import env from "app/utils/env";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const getProfileApiUrl = () => {
	return `${env("USER_AUTH_API_URL")}/me`;
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {
	CLIENT_ID: "050A6ABB-E558-4A2F-936C-D75A1C969CD7",
	RESPONSE_TYPE: "code",
	SCOPE: "user_profile user_profile.phone user_profile.address user_profile.HasCdav",
};
